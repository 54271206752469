<template>
    <el-container>
        <el-aside width="200px">
            <el-scrollbar stype="width:100%">
                <!-- 隐藏aside底部水平滚动条 -->
                <el-row>
                    <el-col :span="24">
                        <div class="empty-menu-item"></div>
                    </el-col>
                </el-row>
                <el-dropdown @command="onSelectGame">
                    <el-button type="success" plain style="width:180px;" round>
                        {{selectGameLabel}}<i class="el-icon-arrow-down el-icon-caret-bottom" style="float:right;"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in options" :key="item.value" :command="item"
                            style="width:136px;">
                            <img :src="item.icon" style="float:left;">
                            <span>{{ item.label }}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-row>
                    <el-col :span="24">
                        <div class="empty-menu-item"></div>
                    </el-col>
                </el-row>
                <el-menu style="width:100%" default-active="GAME_INDEX" class="el-menu-main" background-color="#fff"
                    text-color="#1f354b" active-text-color="#36b97c" @select="handleSelect">
                    <template v-for="(value, name) in mainmenu">
                        <el-menu-item :index="name" :key="name">
                            <i style="font-size:30px;" :class="value.icon"></i>
                            <span slot="title">{{value.label}}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
                <el-row>
                    <el-col :span="24">
                        <div class="menu-takeplace"></div>
                    </el-col>
                </el-row>
            </el-scrollbar>
        </el-aside>

        <el-container>
            <el-header height="60px">
                <el-breadcrumb separator=">" style="margin-top: 20px; float: left;">
                    <el-breadcrumb-item v-for="item in breadcrumbs" :to="{}" :key="item.label">{{item.label}}
                    </el-breadcrumb-item>
                </el-breadcrumb>
                <div style="margin-top: 12px; float: right; color: #888888; font-size: 14px;">{{welcome}}<el-button @click="onClickQuit" size="small" round>退出</el-button></div>
            </el-header>
            <el-container>
                <div class="el-menu-sub bg-submenu" style="width:256px" v-if="showSubMenu">
                    <el-menu :default-active="submenuDefPage" ref="refSubMenu" background-color="#e4f5ef"
                        text-color="#6a8bad" active-text-color="#36b97c" @select="handleSelectSub">
                        <template v-for="item in submenu">
                            <el-menu-item :index="item.index" :key="item.label">
                                <i style="font-size:20px;" :class="item.icon"></i>
                                <span slot="title"> {{ item.label }}</span>
                            </el-menu-item>
                        </template>
                    </el-menu>
                </div>
                <el-main>
                    <div class="main-content" style="margin-left: 8px;">
                        <router-view :key="$route.path+$route.query.name" />
                    </div>
                </el-main>
            </el-container>
        </el-container>
    </el-container>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import Watermark from '../views/watermark.js'

    export default {
        props: {},
        data() {
            return {
                breadcrumbSeq: [{
                    label: "主页",
                    path: ""
                }, {
                    label: "",
                    path: ""
                }],
                mainmenu: {},
                value: '',
                curMenu: 'GAME_INDEX',
            }
        },
        mounted() {
            Watermark.set(this.$store.state.username);
        },
        created: function() {
            this.mainmenu = this.ownMenuPersission();
            console.log("clientHeight", document.documentElement.clientHeight);
        },
        computed: {
            ...mapGetters(
                ['ownGamePermission', 'ownMenuPersission']
            ),
            // 可用的游戏列表
            options: function() {
                return this.ownGamePermission();
            },
            // 当前选择的游戏
            selectGameLabel: function() {
                return this.$store.state.game.label
            },
            // 面包屑
            breadcrumbs: function() {
                return this.breadcrumbSeq
            },
            // 当前子菜单
            submenu: function() {
                return this.mainmenu[this.curMenu].subs
            },
            // 当前子菜单默认页
            submenuDefPage: function() {
                if ('GAME_INDEX' == this.curMenu) {
                    return "0"
                } else {
                    return this.submenu[0].index
                }
            },
            // 是否显示子菜单
            showSubMenu: function() {
                return ('GAME_INDEX' != this.curMenu)
            },
            // 欢迎
            welcome: function() {
                //document.title = "模拟合服专用";                
                return 'v1.1.86.------' + '欢迎 ' + this.$store.state.username + ' ';
            },
        },
        methods: {
            onSelectGame(item) {
                this.$store.commit("setSelectGame", item.value)
                this.$router.push({path:"/",query:{name:item.value}});
                this.curMenu = 'GAME_INDEX'
            },
            handleSelect(menu) {
                this.curMenu = menu
                const subNode = this.submenu[0]
                this.$router.push({path:subNode.path,query:{name:subNode.path}});
                // 面包屑更新
                this.breadcrumbSeq = [{
                        label: this.mainmenu[menu].label,
                        path: '/'
                    },
                    {
                        label: subNode.label,
                        path: '/'
                    },
                ]
                if (this.$refs.refSubMenu) { // 默认第一个吧
                    this.$refs.refSubMenu.activeIndex = subNode.index;
                }
            },
            handleSelectSub(index) {
                for (let i = 0; i < this.submenu.length; i++) {
                    const node = this.submenu[i]
                    if (index == node.index) {
                        this.$router.push(node.path);
                        this.breadcrumbSeq[1] = {
                            label: node.label,
                            path: ''
                        }; // 面包屑更新
                        break;
                    }
                }
            },
            // 退出
            onClickQuit: function() {
                this.$router.push('/login');
            },
        }
    };
</script>

<style>
    /*空白占位行*/
    .empty-menu-item {
        border-radius: 2px;
        min-height: 2px;
    }

    /*空白占位行*/
    .menu-takeplace {
        border-radius: 4px;
        min-height: 2048px;
    }

    /*导航菜单字体大小*/
    .el-menu-item {
        font-size: 15px;
    }

    /*导航菜单项鼠悬停颜色*/
    .el-menu-item:hover {
        background-color: #e4fbf4 !important;
    }

    /*导航菜单项选中颜色*/
    .el-menu-item.is-active {
        background-color: #d8fbf0 !important;
    }

    .bg-submenu {
        background: #e4f5ef;
        height: 2048;
    }

    .el-header {
        background-color: #bbe6d6;
    }

    .el-breadcrumb {
        font-size: 16px;
    }

    .el-footer {
        background-color: #2c3e51;
        text-align: center;
        line-height: 30px;
        margin-bottom: 0px;
    }

    .el-aside {
        background-color: #ffffff;
        text-align: center;
        line-height: 0px;
        box-shadow: 0px 0px 16px #888888;
        z-index: 1000;
    }

    .el-menu-main,
    .el-menu-sub {
        text-align: left;
    }

    .el-main {
        background-color: #c8ebdf;
        text-align: center;
        line-height: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
    }

    .main-content {
        padding-top: 16px;
        text-align: left;
    }

    body {
        margin: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        /*不显示超出界限的内容*/
    }
</style>
