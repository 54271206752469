import Vue from 'vue'
import VueRouter from 'vue-router'

import MainAside from './layout/MainAside.vue'
import Login from './layout/Login.vue'


Vue.use(VueRouter)

const routes = [ 
    //////登录路由//////
    {
        path:'/login',
        component: Login,
        hidden: true
    },
    {
        path: '/',
        //name: 'dashboard',
        component: MainAside,
        redirect: 'index',
        children: [           
            //////主页路由//////
            {
                path:'index',
                name:'index',
                component: () => import('@/views/index.vue')
            },
            //////运营管理路由//////
            {
                path:'notice',
                name:'notice',
                component: () => import('@/views/game_operate/Notice.vue')
            },
            {
                path:'mail',
                name:'mail',
                component: () => import('@/views/game_operate/Mail.vue')
            },
            {
                path:'vip',
                name:'vip',
                component: () => import('@/views/game_operate/Vip.vue')
            }, 
            {
                path:'vipauto',
                name:'vipauto',
                component: () => import('@/views/game_operate/VipAuto.vue')
            },                        
            {
                path:'cdkey',
                name:'cdkey',
                component: () => import('@/views/game_operate/Cdkey.vue')
            },
            {
                path:'racelamp',
                name:'racelamp',
                component: () => import('@/views/game_operate/RaceLamp.vue')
            },            
            {
                path:'payorder',
                name:'payorder',
                component: () => import('@/views/game_operate/PayOrder.vue')
            },
            {
                path:'player',
                name:'player',
                component: () => import('@/views/game_operate/Player.vue')
            },
            {
                path:'unionmgr',
                name:'unionmgr',
                component: () => import('@/views/game_operate/UnionMgr.vue')
            },            
            {
                path:'feedback',
                name:'feedback',
                component: () => import('@/views/game_operate/Feedback.vue')
            },
            {
                path:'cheetlog',
                name:'cheetlog',
                component: () => import('@/views/game_operate/CheetLog.vue')
            },
            {
                path:'risklog',
                name:'risklog',
                component: () => import('@/views/game_operate/RiskLog.vue')
            },            
            {
                path:'riskmgt',
                name:'riskmgt',
                component: () => import('@/views/game_operate/Riskmgt.vue')
            }, 
            {
                path:'riskmgt_pri',
                name:'riskmgt_pri',
                component: () => import('@/views/game_operate/RiskmgtPri.vue')
            }, 
            {
                path:'gameanalysis',
                name:'gameanalysis',
            },
            {
                path:'gameevent',
                name:'gameevent',
            }, 
            //////游戏配置路由//////         
            {
                path:'onlineparams',
                name:'onlineparams',
                component: () => import('@/views/game_config/OnlineParams.vue')
            }, 
            {
                path:'serverlistshow',
                name:'serverlistshow',
                component: () => import('@/views/game_config/ServerListShow.vue')
            },             
            {
                path:'clientpkg',
                name:'clientpkg',
                component: () => import('@/views/game_config/ClientPkgX.vue')
            },   
            {
                path:'activitysheet',
                name:'activitysheet',
                component: () => import('@/views/game_config/ActivitySheet.vue')
            }, 
            {
                path:'clonemgr',
                name:'clonemgr',
                component: () => import('@/views/game_config/CloneMgr.vue')
            },                                 
            //////区服运维路由//////
            {
                path:'serverlist',
                name:'serverlist',
                component: () => import('@/views/game_server/ServerList.vue')
            },  
            {
                path:'mergeserver',
                name:'mergeserver',
                component: () => import('@/views/game_server/MergeServer.vue')
            },             
            {
                path:'serverpkgconfig',
                name:'serverpkgconfig',
                component: () => import('@/views/game_server/ServerPkg.vue'),
                props: {pkgtype: 'config'}
            },
            {
                path:'serverpkgcode',
                name:'serverpkgcode',
                component: () => import('@/views/game_server/ServerPkg.vue'),
                props: {pkgtype: 'code'}
            },
            {
                path:'crossgroupserver',
                name:'crossgroupserver',
                component: () => import('@/views/game_server/PvpGroup.vue'),
            }, 
            {
                path:'chatgroup',
                name:'chatgroup',
                component: () => import('@/views/game_server/ChatGroup.vue'),
            },             
            {
                path:'servertask',
                name:'servertask',
                component: () => import('@/views/game_server/ServerTask.vue'),
            },                         
            {
                path:'systemparams',
                name:'systemparams',
                component: () => import('@/views/game_server/SystemParams.vue'),
            }, 
            //////系统管理路由//////
            {
                path:'userpermission',
                name:'userpermission',
                component: () => import('@/views/system_manage/UserPermission.vue')
            },                                
        ]
    },    
]

// 路由重复进入的错误提示屏蔽
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes // (缩写) 相当于 routes: routes
})

export default router;