const iconM = require('./assets/logo_m.png');
import axios from 'axios'

const gamesCfg = {
    center: {
        service: axios.create({
            baseURL: "http://sygame.racethunder.cn",
            timeout: 5000
        }),
    },
    list: [{
            index: '1',
            // 游戏内部名
            value: 'msgame',
            // 游戏显示名
            label: '梦境壁垒-审',
            // logo显示
            icon: iconM,
            // 接口地址
            interface: 'http://msproject.racethunder.cn',
            // pkg包上传接口
            pkgurl: 'http://msproject.racethunder.cn',
            // 邮件物品配置
            mailItems: [{
                    type: 'item',
                    label: '物品',
                    lua: 'global/global_item.lua',
                    fields: ["id", "item_name", "item_des", "item_quanlity", "item_type_name"],
                },
                {
                    type: 'hero',
                    label: '英雄',
                    lua: 'hero_system/hero_info.lua',
                    fields: ["id", "hero_name", "role_des", "quality", "role_location"],
                },
                {
                    type: 'talk',
                    label: '气泡框',
                    lua: 'global/global_talk_icon.lua',
                    fields: ["id", "name", "name", "quality", "talk_icon"],
                },
                {
                    type: 'frame',
                    label: '头像框',
                    lua: 'global/global_head_frame.lua',
                    fields: ["id", "name", "name", "quality", "icon"],
                },
                {
                    type: 'lord_attire',
                    label: '领主皮肤',
                    lua: 'lord_system/lord_attire.lua',
                    fields: ["id", "name", "type", "sort", "belong"],
                },
                {
                    type: 'lord_title',
                    label: '领主称号',
                    lua: 'lord_system/lord_title.lua',
                    fields: ["id", "name", "name", "sort", "icon"],
                },
            ],
            // 角色查询信息
            playerinfo: {
                labelArr:[{prop:'name',label:'角色昵称'},{prop:'level',label:'领主等级'},{prop:'loding_time',label:'登录时间',ts:1},{prop:'found_time',label:'创角时间',ts:1}], 
                player : 'tbl_player_info', // 角色表
                tables:[ // 要展示的信息表
                    {
                        name: 'tbl_player_info',
                        field: [{prop:'name',field:'name'},{prop:'loding_time',field:'loding_time'},{prop:'found_time',field:'found_time'}],
                    },
                    {
                        name: 'tbl_lord',
                        field:[{prop:'level',field:'level'}]
                    }
                ]
            },
            // 意见反馈内容
            feedbackTypes: [{id:0,name:'全部类型'},{id:1,name:'账号问题'},{id:2,name:'充值问题'},{id:3,name:'发现Bug'},{id:4,name:'新手教程'},{id:5,name:'联系官方'}],
            feedback: [{prop:'des',label:'信息'}],
            // 错误日志
            cheetLogLev:[{id:'0',name:'全部级别'},{id:'1',name:'严重级别'},{id:'2',name:'警告级别'},{id:'3',name:'普通级别'}],
            cheetlog: [{prop:'meta',label:'JSON数据'}],
            // oss信息
            ossbucket: 'msplanproject',
            osspkgdir: 'msproject/pkg/',
        },
    ],
    defgame: 'game1'
};

export default gamesCfg;
