const submenuCfg = {
    GAME_INDEX: {
        label: ' 主页',
        icon: 'el-icon-s-home',
        subs: [{
            index: '0',
            label: '',
            icon: '',
            path: '/',
        }]
    },
    GAME_OPERATE: {
        label: ' 游戏运营',
        icon: 'el-icon-s-marketing',
        subs: [{
                index: '0',
                label: '公告管理',
                icon: 'el-icon-mic',
                path: '/notice',
            },
            {
                index: '1',
                label: '邮件管理',
                icon: 'el-icon-message',
                path: '/mail',
            },
            {
                index: '2',
                label: 'VIP消息通知',
                icon: 'el-icon-phone-outline',
                path: '/vip',
            },
            {
                index: '3',
                label: '自动VIP消息',
                icon: 'el-icon-loading',
                path: '/vipauto',
            },
            {
                index: '4',
                label: '兑换码管理',
                icon: 'el-icon-present',
                path: '/cdkey',
            },
            {
                index: '5',
                label: '跑马灯通知',
                icon: 'el-icon-bell',
                path: '/racelamp',
            },                       
            {
                index: '6',
                label: '支付查询',
                icon: 'el-icon-tickets',
                path: '/payorder',
            },
            {
                index: '7',
                label: '玩家查询',
                icon: 'el-icon-coordinate',
                path: '/player',
            },
            {
                index: '8',
                label: '公会管理',
                icon: 'el-icon-s-help',
                path: '/unionmgr',
            },
            {
                index: '9',
                label: '意见反馈',
                icon: 'el-icon-service',
                path: '/feedback',
            },
            {
                index: '10',
                label: '错误日志',
                icon: 'el-icon-warning-outline',
                path: '/cheetlog',
            },
            {
                index: '11',
                label: '风险日志',
                icon: 'el-icon-lightning',
                path: '/risklog',
            },            
             {
                index: '12',
                label: '言论风控-频道',
                icon: 'el-icon-video-camera',
                path: '/riskmgt',
            },
             {
                index: '13',
                label: '言论风控-汇总',
                icon: 'el-icon-video-camera',
                path: '/riskmgt_pri',
            },            
            {
                index: '14',
                label: '数据统计',
                icon: 'el-icon-wind-power',
                path: '/gameanalysis',
            },  
            {
                index: '15',
                label: '事件埋点',
                icon: 'el-icon-orange',
                path: '/gameevent',
            }, 
        ]
    },
    GAME_CONFIG: {
        label: ' 游戏配置',
        icon: 'el-icon-s-platform',
        subs: [{
                index: '0',
                label: '在线参数',
                icon: 'el-icon-data-analysis',
                path: '/onlineparams',
            },
            {
                index: '1',
                label: '区服列表显示管理',
                icon: 'el-icon-view',
                path: '/serverlistshow',
            },
            {
                index: '2',
                label: '客户端代码包',
                icon: 'el-icon-folder',
                path: '/clientpkg',
            },
            {
                index: '3',
                label: '活动配置sheet',
                icon: 'el-icon-present',
                path: '/activitysheet',
            },
            {
                index: '4',
                label: '克隆帐号模拟',
                icon: 'el-icon-copy-document',
                path: '/clonemgr',
            },
        ]
    },
    GAME_SERVER: {
        label: ' 区服运维',
        icon: 'el-icon-menu',
        subs: [{
                index: '0',
                label: '服务器区服列表',
                icon: 'el-icon-notebook-2',
                path: '/serverlist',
            },
            {
                index: '1',
                label: '区服合并控制台',
                icon: 'el-icon-help',
                path: '/mergeserver',
            },
            {
                index: '2',
                label: '配置包版本管理',
                icon: 'el-icon-folder-checked',
                path: '/serverpkgconfig',
            },
            {
                index: '3',
                label: '代码包版本管理',
                icon: 'el-icon-cpu',
                path: '/serverpkgcode',
            },            
            {
                index: '4',
                label: 'PvP跨服分组管理',
                icon: 'el-icon-connection',
                path: '/crossgroupserver',
            },
            {
                index: '5',
                label: '跨服聊天分组管理',
                icon: 'el-icon-connection',
                path: '/chatgroup',
            },            
            {
                index: '6',
                label: '区服运维任务管理',
                icon: 'el-icon-time',
                path: '/servertask',
            },            
            {
                index: '7',
                label: '通用系统参数设置',
                icon: 'el-icon-setting',
                path: '/systemparams',
            },
        ]
    },
    SYSTEM_MANAGE: {
        label: ' 系统管理',
        icon: 'el-icon-s-custom',
        subs: [{
            index: '0',
            label: '帐号权限',
            icon: 'el-icon-user',
            path: '/userpermission',
        }],
    }
};

export default submenuCfg;
