import Vue from 'vue'
/////////////////
import './plugins/element.js'
import store from './store.js'
import router from './router.js'
import App from './App.vue'

/////////////////
Vue.config.productionTip = false

router.beforeEach(async (to, from, next) => {
    if ('gameanalysis' == to.name) {
        store.getters.gameHttp(3061, {}).then((response) => {
            if (0 == response.data.errcode) {
                response.data.info.token = store.getters.SID;
                response.data.info.username = store.state.username;
                let argvs = encodeURIComponent( JSON.stringify(response.data.info) );
                window.open(response.data.info.web + '/#/' + argvs, '_blank');
                //window.open('http://192.168.0.42:8081/syevent/#/' + argvs, '_blank'); // 本地测试页面用
            } else {
                console.log(response.data.errmsg);
            }
        });
        return;
    } else if ('gameevent' == to.name) {
        store.getters.gameHttp(3061, {system:"syevent"}).then((response) => {
            if (0 == response.data.errcode) {
                response.data.info.token = store.getters.SID;
                response.data.info.username = store.state.username;
                response.data.info.event = store.getters.ownOpPermission("event");
                let argvs = encodeURIComponent( JSON.stringify(response.data.info) );
                window.open(response.data.info.web + '/#/' + argvs, '_blank');
                //window.open('http://192.168.0.123:8082/syevent/#/' + argvs, '_blank'); // 本地测试页面用
            } else {
                console.log(response.data.errmsg);
            }
        });
        return;
    }
    /////////////////////////
    if (store.getters.SID) {
        next()
    } else {
        if ('/login' == to.path) {
            next()
        } else {
            next({
                path: '/login'
            })
        }
    }
})

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App),
})
