import Vue from 'vue'
import Vuex from 'vuex'
import gamesCfg from './games.js'
import menuCfg from './submenu.js'
import axios from 'axios'

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        // 登录的帐号
        username: '',
        // 拥有权限的游戏&菜单
        permissionGames:[],
        permissionMenus:[],
        // 选择的游戏对象
        game: null,
        // 会话id
        sessionid: "",
        // 当前选择游戏的api接口
        gameService: '',
        // 操作权限
        permissionOps:[],
    },
    getters: {
        SID: state => {
            return state.sessionid
        },
        centerHttp: (state) => (cmd, data) => {
            let rqs = JSON.parse(JSON.stringify(data))
            rqs.cmd = cmd
            rqs.session = state.sessionid
            return gamesCfg.center.service({
                method: 'post',
                url: '/sycenter',
                data: rqs
            })
        },
        gameHttp: (state) => (cmd, data) => {
            let rqs = JSON.parse(JSON.stringify(data));
            rqs.cmd = cmd;
            rqs.session = state.sessionid;
            return state.gameService({
                method: 'post',
                url: '/sygame',
                data: rqs
            })
        },
        // 拥有权限的游戏
        ownGamePermission: (state) => () => {
            return gamesCfg.list.filter((item)=>{
                for (let i=0; i<state.permissionGames.length; i++) {
                    if (item.value == state.permissionGames[i]) {
                        return true;
                    }
                }
                return false;
            });
        },
        // 拥有权限的菜单
        ownMenuPersission: (state) => () => {
            // 判断权限列表里是否正在菜单项
            let ownright = (path)=>{
                for (let i=0; i<state.permissionMenus.length; i++) {
                    if (path == '/'+state.permissionMenus[i]) {
                        return true;
                    }
                }
                return false;
            };
            // 提取权限菜单
            let menus = {}
            let cloneCfg = JSON.parse(JSON.stringify(menuCfg));
            for (let k in cloneCfg) {
                let node = cloneCfg[k];
                let subs = [];
                for (let i=0; i<node.subs.length; i++) {
                    if (ownright(node.subs[i].path)) {
                        subs.push( node.subs[i] );
                    }
                }
                if (subs.length > 0) {
                    menus[k] = node;
                    menus[k].subs = subs;
                }
            }
            //console.log("menus=>", menus);
            return menus;
        },
        // 是否拥有指定的操作权限
        ownOpPermission:(state) => (name) => {
            for (let i=0; i<state.permissionOps.length; i++) {
                if (name == state.permissionOps[i]) {
                    return true;
                }
            }
            return false;
        },
    },
    mutations: {
        setSessionId(state, info) {
            state.username = info.username;
            state.sessionid = info.sid;
            state.permissionGames = info.pgames;
            state.permissionMenus = info.pmenus;
            state.permissionOps = info.poperations;
            state.permissionMenus.push(''); // 主页默认有         
        },
        setSelectGame(state, game) {
            state.selectGame = game;
            for (let i = 0; i < gamesCfg.list.length; i++) {
                if (gamesCfg.list[i].value == game) {
                    let cfg = gamesCfg.list[i];
                    state.game = cfg;
                    state.gameService = axios.create({ baseURL: cfg.interface, timeout: 1200000});                    
                    break;
                }
            }
        }
    }
})


export default store;
