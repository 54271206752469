<template>
    <el-container class="login-container">
        <el-header class="login-head" height="188px">
            <img src="../assets/head-bar1.png">
        </el-header>
        <el-main class="login-main">
            <el-row :gutter="0">
                <el-col :span="13">
                    <div class="login-grid-content"></div>
                </el-col>
                <el-col :span="11" class="el-col-login">
                    <div class="login-grid-content">
                        <el-card class="login-box-card">
                            <el-form :model="ruleForm" ref="ruleForm" label-width="50px" class="login-form">
                                <el-form-item label="帐号" prop="user">
                                    <el-input v-model="ruleForm.user" autocomplete="true"></el-input>
                                </el-form-item>
                                <el-form-item label="密码" prop="password">
                                    <el-input type="password" v-model="ruleForm.password" autocomplete="true"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm()" icon="el-icon-user">登 录</el-button>
                                </el-form-item>
                            </el-form>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-main>
        <el-footer class="login-footer">
            <div>
                <p>Copyright © 2013 -2023HSN. All Rights Reserved.</p>
                <P>海南斐彤科技有限公司 版权所有</p>
                <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">琼ICP备2023008304号</a></p>
            </div>
        </el-footer>
    </el-container>
</template>

<script>
    import { mapGetters } from 'vuex'
    import gamesCfg from '../games.js'
    import md5 from 'js-md5'

    export default {
        data() {
            return {
                defgame: gamesCfg.defgame,
                options: gamesCfg.list,
                ruleForm: {
                    user: '',
                    password: '',
                },
            };
        },
        computed: {
            ...mapGetters(
                ['centerHttp']
            )
        },
        created: function() {
            document.title = "斐彤后台";
        },
        methods: {
            submitForm() {
                if (0 == this.ruleForm.user.length || 0 == this.ruleForm.password.length) {
                    this.$message.error("请输入帐号密码！")
                    return
                }
                // 发送登录请求
                let data = {username:this.ruleForm.user, password:md5(this.ruleForm.password)};
                this.centerHttp(101, data).then((response) => {
                    const rsp = response.data
                    if (0 == rsp.errcode) {
                        this.$message.success("登录成功！");
                        let param = {sid:rsp.session, pgames:rsp.pgames, pmenus:rsp.pmenus, poperations:rsp.poperations, username:this.ruleForm.user};
                        this.$store.commit("setSessionId", param);
                        this.$store.commit("setSelectGame", param.pgames[0]);// 默认第一个游戏                       
                        this.$router.push('/index');
                    } else {
                        this.$message.error(rsp.errmsg);
                    }
                 });
            }
        }
    }
</script>

<style>
    .login-head {
        background-color: #ffffff;
        text-align: left;
        padding: 0px;
    }

    .login-footer {
        background-color: #ffffff;
        text-align: center;
        color: #888888;
        padding: 0px;
        font-size: 10px;
    }

    .login-main {
        background: url("../assets/foot-beijing.png");
        height: 623px;
    }

    .login-grid-content {
        min-height: 8px;
    }

    .el-col-login {
        margin-top: 50px;
    }

    .login-box-card {
        width: 400px;
        height: 260px;
    }

    .login-form {
        padding-top: 32px;
    }
</style>